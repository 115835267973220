<template>
  <div class="card card-profile">
    <div class="row justify-content-center">
      <div class="col-lg-3">
        <avatar :username="fullName" :size="120" :lighten="90"></avatar>
      </div>
    </div>

    <div class="card-body pt-0">
      <div class="row">
        <div class="col">
          <div class="card-profile-stats d-flex justify-content-center">
            <div class="text-center">
              <h5 class="h2" v-if="order.customerId">
                <router-link :to="{name: 'customer-view', params: {id: order.customerId}}" class="font-weight-bold">{{ fullName }}</router-link>
              </h5>
              <h5 class="h2" v-else>
                {{ fullName }}
              </h5>
              <div class="h5 font-weight-500">
                {{ user.street }}<br>{{ user.postcode }} {{ user.city }}<br>{{ user.countryCode }}
              </div>
              <div class="h5 font-weight-500">
                <i class="fas fa-envelope"></i>
                {{ user.email }}
              </div>
              <div class="h5 font-weight-500">
                <i class="fas fa-phone"></i>
                {{ user.phone }}
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="row bg-info" v-if="delivery">
        <div class="col">
          <div class="text-center">
            <span class="heading">Dostawa: {{ deliveryData.type }}</span>
          </div>
          <div class="text-center" v-if="deliveryData.parcel_number">
            Przesyłka {{ deliveryData.courier }}: <span class="heading">{{ deliveryData.parcel_number }}</span>
          </div>
          <div class="text-center" v-if="deliveryData.box">
            Paczkomat: <span class="heading">{{ deliveryData.box }}</span>
          </div>
        </div>
      </div>

      <div class="row bg-secondary" v-if="delivery">
        <div class="col">
          <div class="card-profile-stats d-flex justify-content-center">
            <div class="text-center">
              <h5 class="h3">{{ delivery.firstName }} {{ delivery.lastName }}</h5>
              <div class="h4 font-weight-200" v-if="delivery.company">
                {{ delivery.company }}
              </div>
              <div class="h5 font-weight-500">
                {{ delivery.street }}<br>{{ delivery.postcode }} {{ delivery.city }}<br>{{ delivery.countryCode }}
              </div>
              <div class="h5 font-weight-500">
                <i class="fas fa-envelope"></i>
                {{ delivery.email }}
              </div>
              <div class="h5 font-weight-500">
                <i class="fas fa-phone"></i>
                {{ delivery.phone }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Avatar from "@/components/Avatar";

export default {
  name: "OrderCustomerCard",
  components: {
    Avatar,
  },
  props: {
    order: {
      type: Object,
    },
  },
  computed: {
    fullName() {
      return `${this.order.billingAddress.firstName} ${this.order.billingAddress.lastName}`;
    },
    user() {
      return this.order.billingAddress;
    },
    delivery() {
      return this.order.deliveryAddress;
    },
    deliveryData() {
      return this.order.deliveryData;
    },
  },
};
</script>

<style>
.card-profile div div .vue-avatar--wrapper {
  margin-top: 30px;
}
</style>
