<template>
  <div class="card">
    <div class="card-header">
      <h5 class="h3 mb-0">Uwagi do zamówienia</h5>
    </div>
    <div class="card-body">
      <div class="row">
        <div class="col-sm-10">
          <p>{{ order.notes }}</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'OrderNotesCard',
  props: {
    order: {
      type: Object,
    },
  },
};
</script>
