<template>
  <div class="card">
    <div class="card-header">
      <h5 class="h3 mb-0">Historia</h5>
    </div>
    <div class="card-body">
      <OrderTimelineStatusItem
        v-for="status in statuses"
        :key="status.status"
        :status="status.status"
        :action="status.action"
        :created-at="status.createdAt"
        :type="status.type"
      />
    </div>
  </div>
</template>

<script>
import OrderTimelineStatusItem from "@/views/Store/Order/OrderTimelineStatusItem";

export default {
  name: 'OrderTimelineCard',
  components: {OrderTimelineStatusItem},
  props: {
    statuses: {
      type: Array,
      required: true
    }
  },
  data() {
    return {
      events: [
        {
          title: 'Utworzenie zamówienia',
          icon: 'ni ni-like-2',
          type: 'success',
          time: '10:12:12',
          description: 'IP: 255.255.255.255, urządzenie: iPhone X',
        },
        {
          title: 'Płatność online',
          icon: 'ni ni-money-coins',
          type: 'danger',
          time: '10:13:12',
          description: 'Odrzucona transakcja',
        },
        {
          title: 'Płatność online 2',
          icon: 'ni ni-money-coins',
          type: 'info',
          time: '10:16:01',
          description: 'Płatność zatwierdzona',
        },
        {
          title: 'Wystawiona faktura w Symfonii',
          icon: 'ni ni-single-copy-04',
          type: 'info',
          time: '10:21:01',
          description: 'Numer FV: 123/45/67/89',
        },
        {
          title: 'Przekazane do realizacji',
          icon: 'ni ni-app',
          type: 'info',
          time: '10:21:11',
          description: 'Juz tam będą pakować',
        },
        {
          title: 'Wysłane',
          icon: 'ni ni-delivery-fast',
          type: 'info',
          time: '13:19:48',
          description: 'Juz się zbliża',
        },
        {
          title: 'Odebrana',
          icon: 'ni ni-trophy',
          type: 'info',
          time: '18:58:51',
          description: 'Cieszymy się?',
        },
        {
          title: 'Zakończona',
          icon: 'ni ni-check-bold',
          type: 'success',
          time: '20:01:01',
          description: 'Naliczenie punktów',
        },
        {
          title: 'Anulowana',
          icon: 'ni ni-2x ni-fat-remove',
          type: 'danger',
          time: '22:01:01',
          description: 'Bo coś się komuś nie spodobało',
        },
      ]
    }
  },
  methods: {
    getType(item) {
      if (
        ['order-placed', 'accepted'].includes(item.status)
      ) {
        return 'success'
      }
    }
  }
}
</script>
