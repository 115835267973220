<template>
  <div>
    <base-header class="pb-6" type="primary">
      <breadcrumb-header :items="breadcrumb"></breadcrumb-header>
      <div class="row">
        <div class="col-xl-3 col-md-6">
          <stats-card title="Zamówienie"
                      type="gradient-success"
                      :sub-title="itemData.number"
                      icon="ni ni-check-bold">
            <template slot="footer">
              <span class="text-nowrap mr-3">Status</span>
              <span class="text-danger mr-3 font-weight-500"><i class="fa fa-check-square"></i> {{ itemData.status }}</span>
              <button class="btn btn-sm btn-danger" @click="modals.cancelForm = true" v-if="canCancel">Anulowanie</button>
            </template>
          </stats-card>
        </div>
        <div class="col-xl-3 col-md-6">
          <stats-card title="Płatność"
                      type="gradient-info"
                      :sub-title="itemData.paymentData.method"
                      icon="ni ni-credit-card">
            <template slot="footer">
              <span class="text-nowrap mr-6"></span>
              <span class="text-nowrap mr-6">
                <button class="btn btn-sm btn-primary" @click="modals.transferForm = true" v-if="canConfirmTransfer">Potwierdzenie</button>
              </span>
            </template>
          </stats-card>
        </div>
        <div class="col-xl-3 col-md-6">
          <stats-card title="Dostawa"
                      type="gradient-info"
                      :sub-title="itemData.deliveryData.type"
                      icon="ni ni-delivery-fast">
            <template slot="footer">
              <div class="d-flex justify-content-between align-items-center">
                <div>
                  <span class="text-nowrap mr-3">Opłata</span>
                  <span class="text-primary mr-3 font-weight-500"><i class="fa fa-coins"></i> {{ itemData.deliveryAmount }} zł</span>
                </div>
                <div v-if="canPickup">
                  <div class="btn btn-primary btn-sm" @click="handlePickupForm">Gotowe do odbioru</div>
                </div>
              </div>
            </template>
          </stats-card>
        </div>
        <div class="col-xl-3 col-md-6">
          <stats-card title="Kwota zamówienia"
                      type="gradient-info"
                      :sub-title="`${itemData.totalAmount} zł`"
                      icon="ni ni-money-coins">
            <template slot="footer">
              <span class="text-nowrap mr-3">Produkty</span>
              <span class="text-primary mr-3 font-weight-500"><i class="fa fa-coins"></i> {{ itemData.itemsAmount }} zł</span>
<!--              <span class="text-nowrap mr-3">Rabat</span>-->
<!--              <span class="text-danger mr-3 font-weight-500"><i class="fa fa-coins"></i> {{ itemData.discountAmount }} zł</span>-->
            </template>
          </stats-card>
        </div>
      </div>
    </base-header>

    <div class="container-fluid mt--6">
      <div class="row">
        <div class="col-xl-4">
          <order-customer-card :order="itemData"></order-customer-card>
        </div>
        <div class="col-xl-8">
          <order-product-list :order="itemData"></order-product-list>
        </div>
      </div>

      <div class="row">
        <div class="col-xl-4">
          <order-timeline-card :statuses="orderStatus"></order-timeline-card>
        </div>
        <div class="col-xl-8">
          <order-notes-card :order="itemData"></order-notes-card>
        </div>
      </div>
    </div>

    <form role="form" @submit.prevent="handleCancelForm">
      <modal :show.sync="modals.cancelForm" body-classes="p-0">
        <h3 slot="header" class="mb-0">Anulowanie zamówienia</h3>
        <card type="secondary"
              header-classes="bg-transparent pb-5"
              body-classes="px-lg-5 py-lg-5"
              class="border-0 mb-0">
          <template>
            <div class="row">
              <div class="col-md-12">
                <base-input label="Czy na pewno chcesz anulować zamówienie?" placeholder="">
                  <el-select v-model="cancelForm.confirm" filterable placeholder="Wybierz opcję">
                    <el-option key="no" label="Nie" value="no"></el-option>
                    <el-option key="yes" label="Tak" value="yes"></el-option>
                  </el-select>
                </base-input>
              </div>
            </div>
            <div class="row">
              <div class="col-md-12">
                <base-input label="Powód" placeholder="">
                  <el-select v-model="cancelForm.reason" filterable placeholder="Wybierz opcję">
                    <el-option key="customer" label="Na prośbę klienta" value="na prośbę klienta"></el-option>
                    <el-option key="payment" label="Brak płatności" value="brak płatności"></el-option>
                    <el-option key="product" label="Brak produktu" value="brak produktu"></el-option>
                    <el-option key="duplicate" label="Powtórzone zamówienie" value="powtórzone zamówienie"></el-option>
                    <el-option key="others" label="Inny" value="inny"></el-option>
                  </el-select>
                </base-input>
              </div>
            </div>
            <div class="row">
              <div class="col-md-12">
                <base-input label="Uwagi/Komentarz">
                <textarea
                  class="form-control mb-12"
                  placeholder="Opis"
                  rows="4"
                  v-model="cancelForm.remarks"
                ></textarea>
                </base-input>
              </div>
            </div>
          </template>
        </card>
        <template slot="footer">
          <base-button type="link" class="ml-auto" @click="modals.cancelForm = false">Zamknij</base-button>
          <base-button type="danger" native-type="submit">Potwierdź</base-button>
        </template>
      </modal>
    </form>
    <form role="form" @submit.prevent="handleTransferForm">
      <modal :show.sync="modals.transferForm" body-classes="p-0">
        <h3 slot="header" class="mb-0">Potwierdzenie przelewu</h3>
        <card type="secondary"
              header-classes="bg-transparent pb-5"
              body-classes="px-lg-5 py-lg-5"
              class="border-0 mb-0">
          <template>
            <div class="row">
              <div class="col-md-12">
                <base-input type="date" label="Data przelewu" placeholder="" v-model="transferForm.date">
                </base-input>
              </div>
            </div>
            <div class="row">
              <div class="col-md-12">
                <base-input label="Uwagi/Komentarz">
                <textarea
                  class="form-control mb-12"
                  placeholder="Opis"
                  rows="4"
                  v-model="transferForm.remarks"
                ></textarea>
                </base-input>
              </div>
            </div>
          </template>
        </card>
        <template slot="footer">
          <base-button type="link" class="ml-auto" @click="modals.transferForm = false">Zamknij</base-button>
          <base-button type="primary" native-type="submit">Potwierdź</base-button>
        </template>
      </modal>
    </form>

  </div>
</template>

<script>
import {createNamespacedHelpers, mapActions} from 'vuex'

import StatsCard from '@/components/Cards/StatsCard';

import OrderCustomerCard from "@/views/Store/Order/OrderCustomerCard";
import OrderProductList from "@/views/Store/Order/OrderProductList";
import OrderTimelineCard from "@/views/Store/Order/OrderTimelineCard";
import BreadcrumbHeader from "@/views/Store/Layout/BreadcrumbHeader";
import {Option, Select} from "element-ui";
import OrderNotesCard from "@/views/Store/Order/OrderNotesCard";

const {mapState} = createNamespacedHelpers('OrderViewModule');

export default {
  name: 'OrderView',
  components: {
    OrderNotesCard,
    BreadcrumbHeader,
    StatsCard,

    OrderCustomerCard,
    OrderProductList,
    OrderTimelineCard,
    [Select.name]: Select,
    [Option.name]: Option,
  },
  data() {
    return {
      modals: {
        cancelForm: false,
        transferForm: false,
      },
      cancelForm: {
        confirm: '',
        reason: '',
        remarks: '',
      },
      transferForm: {
        confirm: '',
        date: '',
        remarks: '',
      },
    };
  },
  computed: {
    ...mapState({
      itemData: state => state.item,
      orderStatus: state => state.item?.histories ?? [],
    }),
    breadcrumb() {
      return [
        {title: 'Zamówienia', link: {name: 'orders'}},
        {title: 'Detaliczne', link: {name: 'order-list'}},
        {title: `Zamówienie ID: ${this.$route.params.id}`}
      ];
    },
    canCancel() {
      return ['pending','in_progress','accepted'].includes(this.itemData.status);
    },
    canComplete() {
      return ['in_progress', 'completed'].includes(this.itemData.status);
    },
    canPickup() {
      const isPickupMethod = ['pickup'].includes(this.itemData?.data?.delivery?.code ?? '')
      return this.canComplete && isPickupMethod
    },
    canConfirmTransfer() {
      // return true;
      return ['pending'].includes(this.itemData.status) && ['transfer', 'online', 'card', 'blik', 'paypo'].includes(this.itemData.paymentData?.code);
    },
  },
  methods: {
    ...mapActions('OrderViewModule', ['updateOrder', 'sendTransferForm']),
    async handleCancelForm() {
      const confirm = this.cancelForm.confirm;
      const reason = this.cancelForm.reason;
      const remarks = this.cancelForm.remarks;

      const orderId = this.$route?.params?.id;
      await this.updateOrder({ orderId, data: { confirm, reason, remarks }, status: 'cancel'});

      this.modals.cancelForm = false;

      this.$store.commit('OrderViewModule/resetItem', []);
      await this.$store.dispatch('OrderViewModule/fetchItem', {
        token: this.$store.state.Auth.token,
        id: this.$route.params.id,
      });
    },
    async handlePickupOrderReadyToCollect() {
      const confirm = 'YES'
      const reason = 'Zamówienie gotowe do odbioru';
      const remarks = '';

      const orderId = this.$route?.params?.id;
      await this.updateOrder({ orderId, data: { confirm, reason, remarks }, status: 'collect'});

      this.modals.cancelForm = false;

      this.$store.commit('OrderViewModule/resetItem', []);
      await this.$store.dispatch('OrderViewModule/fetchItem', {
        token: this.$store.state.Auth.token,
        id: this.$route.params.id,
      });
    },
    async handlePickupOrderCompleted() {
      const confirm = 'YES'
      const reason = 'Zamówienie odebrane';
      const remarks = '';

      const orderId = this.$route?.params?.id;
      await this.updateOrder({ orderId, data: { confirm, reason, remarks }, status: 'complete'});

      this.modals.cancelForm = false;

      this.$store.commit('OrderViewModule/resetItem', []);
      await this.$store.dispatch('OrderViewModule/fetchItem', {
        token: this.$store.state.Auth.token,
        id: this.$route.params.id,
      });
    },
    async handlePickupForm() {
      if ( this.canComplete ) {
       return  await this.handlePickupOrderCompleted();
      }
      return await this.handlePickupOrderReadyToCollect();
    },
    async handleTransferForm() {
      const confirm = 'YES';
      const date = this.transferForm.date;
      const remarks = this.transferForm.remarks;

      const orderId = this.$route?.params?.id;
      await this.sendTransferForm({ orderId, data: { confirm, date, remarks }});

      this.modals.transferForm = false;

      this.$store.commit('OrderViewModule/resetItem', []);
      await this.$store.dispatch('OrderViewModule/fetchItem', {
        token: this.$store.state.Auth.token,
        id: this.$route.params.id,
      });
    },
  },
  beforeMount() {
    this.$store.dispatch('OrderViewModule/fetchItem', {
      token: this.$store.state.Auth.token,
      id: this.$route.params.id,
    });
  },
};
</script>
