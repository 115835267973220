<template>
  <div class="timeline timeline-one-side pb-4" data-timeline-content="axis" data-timeline-axis-style="dashed">
    <div class="timeline-block">
          <span class="timeline-step" :class="`badge-${badgeType}`">
            <i :class="getIcon"></i>
          </span>
      <div class="timeline-content pt-0">
        <div class="d-flex justify-content-between pt-1">
          <div>
            <span class="text-muted text-sm font-weight-bold">Status: {{ $ct[status] || status }}</span>
          </div>
          <div class="text-right">
            <small class="text-muted"><i class="fas fa-clock mr-1"></i>{{createdAt}}</small>
          </div>
        </div>
        <!--            <h6 class="text-sm mt-1 mb-0">{{event.description}}</h6>-->
        <h6 class="text-sm mt-1 mb-0">{{ $ct[action] || action }}</h6>
      </div>
    </div>
  </div>
</template>

<script>
import OrderStatusesTranslations from "@/i18n/order-statuses";
import OrderActionsTranslations from "@/i18n/order-actions";

export default {
  name: 'OrderTimelineStatusItem',
  props: {
    action: {
      type: String,
      required: true
    },
    createdAt: {
      type: String,
      required: true
    },
    status: {
      type: String,
      required: true
    },
  },
  computed: {
    getIcon() {
      switch (this.action) {
        case 'create':
          return 'ni ni-like-2';
        case 'email-confirm':
          return 'ni ni-email-83';
        case 'payment':
          return 'ni ni-money-coins';
        case 'email-paid':
          return 'ni ni-email-83';
        default:
          return 'ni ni-support-16';
      }
    },
    badgeType() {
      //eslint-disable-next-line
      if ( ['create', 'email-paid'].includes(this.action) )  return 'success';
      if ( ['email-confirm', 'payment'].includes(this.action) )  return 'info';
      return 'primary'
    },
    $ct() {
      return {
        ...OrderActionsTranslations,
        ...OrderStatusesTranslations
      }
    }
  }
}
</script>
