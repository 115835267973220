<template>
  <div class="card">
    <div class="card-header border-0">
      <div class="row align-items-center">
        <div class="col-8">
          <h3 class="mb-0">Produkty</h3>
        </div>
        <div class="col-4 text-right">
          <button class="btn btn-sm btn-danger" @click="notifyVue('danger')">Danger</button>
          <a href="#!" class="btn btn-sm btn-primary">Settings</a>
        </div>
      </div>
    </div>

    <el-table class="table-responsive table"
              :data="tableData"
              header-row-class-name="thead-light">
      <el-table-column label="Nazwa produktu"
                       min-width="140px"
                       prop="name">
        <template v-slot="{row}">
          <router-link :to="{name: 'product-view', params: {id: row.id}}" class="font-weight-bold">
            {{ row.name }}
          </router-link>
          <span class="badge-block">
            <badge type="info" size="big">EAN: {{ row.ean }}</badge>
          </span>
        </template>
      </el-table-column>
      <el-table-column label="Cena sprzedaży"
                       min-width="50px"
                       align="right"
                       prop="price">
        <template v-slot="{row}">
          <span class="text-muted text--cross" v-if="row.basePrice !== row.price">{{ row.basePrice }}</span>
          {{ row.price }}
        </template>
      </el-table-column>
      <el-table-column label="Sztuk"
                       min-width="30px"
                       prop="qty">
      </el-table-column>
      <el-table-column label="Suma"
                       min-width="35px"
                       align="right"
                       prop="total">
      </el-table-column>
      <el-table-column min-width="5px">
      </el-table-column>
    </el-table>

    <el-table class="table-responsive table"
              :data="tableSummarize"
              :show-header=false>
      <el-table-column min-width="140px" prop="info"></el-table-column>
      <el-table-column min-width="80px"></el-table-column>
      <el-table-column min-width="35px" align="right" prop="amount"></el-table-column>
      <el-table-column min-width="5px"></el-table-column>
    </el-table>
  </div>
</template>

<script>
import {Table, TableColumn} from "element-ui";

export default {
  name: "OrderProductList",
  components: {
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
  },
  props: {
    order: {
      type: Object,
    },
  },
  computed: {
    tableData() {
      return this.order.items;
    },
    tableSummarize() {
      const coupon = this.order.data?.coupon?.code ? ' (Kupon: ' + this.order.data?.coupon?.code + ')' : '';
      return [
        {info: 'Podsumowanie', amount: this.order.itemsAmount},
        {info: 'Wysyłka i obsługa', amount: this.order.deliveryAmount},
        {info: 'Zniżka' + coupon, amount: (-this.order.discountAmount).toFixed(2)},
        {info: 'Łącznie', amount: this.order.totalAmount},
      ];
    },
  },
  methods: {
    notifyVue(type = 'default') {
      this.$notify({
        message: 'Welcome to <b>Cloche</b>. Please do not do again!',
        timeout: 5000,
        icon: 'ni ni-bell-55',
        type
      });
    },
  },
};
</script>

<style>
.text-muted.text--cross {
  text-decoration: line-through;
  padding-right: 8px;
}
.badge-block {
  display: block;
  font-size: 0.9rem;
}
.badge-big {
  font-size: 80%;
  margin-right: 0.5em;
}
</style>
