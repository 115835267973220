export default {
  'pending': 'Oczekujące',
  'paid': 'Opłacone',
  'accepted': 'Przekazano do realizacji',
  'in_progress': 'W trakcie realizacji',
  'shipped': 'Wysłane',
  'delivered': 'Dostarczone',
  'completed': 'Zakończone',
  'cancelled': 'Anulowane'
}
